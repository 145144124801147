import Jadwal from './components/Jadwal';
import logoEdhos from 'assets/img/logo.png';
import Moment from 'react-moment';
import 'moment/locale/id';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

import { useEffect, useMemo, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import Loader from 'components/Loader';
import { get } from 'utils/request';
import { useNavigate, } from 'react-router-dom';

function DocterSchedule() {
  const navigate = useNavigate()
  const [dayOfWeekIso, setDayOfWeekIso] = useState(new Date().getDay());
  const [currentDateTime, setCurrentDateTime] = useState(
    new Date().toISOString()
  );

  const [dataSchedule, setDataSchedule] = useState([]);
  const [dataPoli, setDataPoli] = useState([]);
  const [listDocter, setListDocter] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(true);

  const [activeIndex, setActiveIndex] = useState(0);
  // page 1
  const [dataPage1Col1, setDataPage1Col1] = useState([]);
  const [dataPage1Col2, setDataPage1Col2] = useState([]);
  const [dataPage1Col3, setDataPage1Col3] = useState([]);
  const [dataPage1Col4, setDataPage1Col4] = useState([]);
  // page 2
  const [dataPage2Col1, setDataPage2Col1] = useState([]);
  const [dataPage2Col2, setDataPage2Col2] = useState([]);
  const [dataPage2Col3, setDataPage2Col3] = useState([]);
  const [dataPage2Col4, setDataPage2Col4] = useState([]);
  // page 3
  const [dataPage3Col1, setDataPage3Col1] = useState([]);
  const [dataPage3Col2, setDataPage3Col2] = useState([]);
  const [dataPage3Col3, setDataPage3Col3] = useState([]);
  const [dataPage3Col4, setDataPage3Col4] = useState([]);
  // page 4
  const [dataPage4Col1, setDataPage4Col1] = useState([]);
  const [dataPage4Col2, setDataPage4Col2] = useState([]);
  const [dataPage4Col3, setDataPage4Col3] = useState([]);
  const [dataPage4Col4, setDataPage4Col4] = useState([]);
  // page 5
  const [dataPage5Col1, setDataPage5Col1] = useState([]);
  const [dataPage5Col2, setDataPage5Col2] = useState([]);
  const [dataPage5Col3, setDataPage5Col3] = useState([]);
  const [dataPage5Col4, setDataPage5Col4] = useState([]);
  // page 6
  const [dataPage6Col1, setDataPage6Col1] = useState([]);
  const [dataPage6Col2, setDataPage6Col2] = useState([]);
  const [dataPage6Col3, setDataPage6Col3] = useState([]);
  const [dataPage6Col4, setDataPage6Col4] = useState([]);
  // page 7
  const [dataPage7Col1, setDataPage7Col1] = useState([]);
  const [dataPage7Col2, setDataPage7Col2] = useState([]);
  const [dataPage7Col3, setDataPage7Col3] = useState([]);
  const [dataPage7Col4, setDataPage7Col4] = useState([]);
  // page 8
  const [dataPage8Col1, setDataPage8Col1] = useState([]);

  const getDataPoli = () => {
    return new Promise((resolve, reject) => {
      get(`/get-poli?is_klinik=true`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const getDataJadwal = async (day = 0) => {
    return new Promise((resolve, reject) => {
      get(`/get-schedule-docter?day=${day}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const getListDocter = () => {
    return new Promise((resolve, reject) => {
      get(`/get-docter`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const initFunction = async () => {
    setLoadingProgress(true);
    const { data: listDocter } = await getListDocter();
    setListDocter(listDocter);
    const currentDay = new Date().getDay();
    const { data: listPoli } = await getDataPoli();
    const { data: listSchedule } = await getDataJadwal(currentDay);

    // console.log("listPoli", listPoli);

    let dataPoliMergedJadwal = [];
    listPoli.forEach((poli, index) => {
      const poliIndex = listPoli.findIndex((val) => val.did === poli.did);
      dataPoliMergedJadwal.push({
        ...listPoli[poliIndex],
        dokter: listSchedule.filter((val) => val.did === poli.did),
      });
    });

    // console.log("listPoli", listPoli);
    // console.log("listSchedule", listSchedule);
    // console.log("dataPoliMergedJadwal", dataPoliMergedJadwal);
    await mapingSchedule(dataPoliMergedJadwal);

    setLoadingProgress(false);
  };

  const isChangeDaysOfWeekIso = (newValue) => {
    setDayOfWeekIso((prev) => {
      if (prev !== newValue) {
        // getDataJadwal(newValue);
        return newValue;
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    initFunction();
  }, []);

  useEffect(() => {
    initFunction();
    // toLocaleString('id-ID', { timeZone: 'Asia/Jakarta' })
  }, [dayOfWeekIso]);

  const mapingSchedule = (schedules) => {
    console.log('schedules', schedules)
    return new Promise((resolve, reject) => {
      schedules.forEach((schedule) => {
        switch (schedule.name_short.toLowerCase()) {
          case 'klinik anak':
            resolve(setDataPage1Col1(schedule));
            break;
          case 'klinik vaksin':
            resolve(setDataPage1Col2(schedule));
            break;
          case 'klinik tumbuh kembang':
            resolve(setDataPage1Col3(schedule));
            break;
          case 'klinik laktasi':
            resolve(setDataPage1Col4(schedule));
            break;
          case 'klinik obgyn':
            resolve(setDataPage2Col1(schedule));
            break;
          case 'klinik penyakit dalam':
            resolve(setDataPage2Col2(schedule));
            break;
          case 'klinik bedah digestif':
            resolve(setDataPage2Col3(schedule));
            break;
          case 'klinik bedah umum':
            resolve(setDataPage2Col4(schedule));
            break;
          case 'klinik bedah onkologi':
            resolve(setDataPage3Col1(schedule));
            break;
          case 'klinik orthopedi':
            resolve(setDataPage3Col2(schedule));
            break;
          case 'klinik bedah plastik':
            resolve(setDataPage3Col3(schedule));
            break;
          case 'klinik tht kl':
            resolve(setDataPage3Col4(schedule));
            break;
          case 'klinik rehab medik':
            resolve(setDataPage4Col1(schedule));
            break;
          case 'klinik jantung':
            resolve(setDataPage4Col2(schedule));
            break;
          case 'klinik gizi':
            resolve(setDataPage4Col3(schedule));
            break;
          case 'klinik kulit dan kelamin':
            resolve(setDataPage4Col4(schedule));
            break;
          case 'klinik radiologi intervensi':
            resolve(setDataPage5Col1(schedule));
            break;
          case 'klinik gigi spesialis anak':
            resolve(setDataPage5Col2(schedule));
            break;
          case 'klinik gigi spesialis':
            resolve(setDataPage5Col3(schedule));
            break;
          case 'klinik bedah mulut':
            resolve(setDataPage5Col4(schedule));
            break;
          case 'klinik gigi umum':
            resolve(setDataPage6Col1(schedule));
            break;
          case 'klinik mata':
            resolve(setDataPage6Col2(schedule));
            break;
          case 'klinik psikiatri':
            resolve(setDataPage6Col3(schedule));
            break;
          case 'klinik psikologi':
            resolve(setDataPage6Col4(schedule));
            break;
          case 'klinik urologi':
            resolve(setDataPage7Col1(schedule));
            break;
          case 'klinik pain center':
            resolve(setDataPage7Col2(schedule));
            break;
          case 'klinik bedah umum reguler':
            resolve(setDataPage7Col3(schedule));
            break;
          case 'klinik penyakit dalam reguler':
            resolve(setDataPage7Col4(schedule));
            break;
          case 'klinik obgyn reguler':
            resolve(setDataPage8Col1(schedule));
            break;
          default:
        }
      });
    });
  };

  // if (dataSchedule.length > 0) {
  return (
    <div className='relative App'>
      <AnimatePresence mode={loadingProgress ? 'wait' : 'sync'}>
        {loadingProgress && (
          <div className='flex items-center justify-center h-screen'>
            <motion.div
              initial={{ scale: 0.1, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,
                transition: { duration: 0.3 },
              }}
              exit={{
                scale: [3, 0.1],
                opacity: 0,
                transition: { duration: 0.4 },
              }}
              // transition={{ type: "spring", duration: 0.3 }}
              className='p-5 transition-all bg-white rounded-full shadow-lg'
            // style='--value:70;'
            >
              <Loader />
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      {!loadingProgress && (
        <>
          <img
            src={logoEdhos}
            alt='Logo'
            className='absolute w-32 top-4 left-4'
            onClick={() => { navigate('/') }}
          />

          <h1 className='pt-4 text-4xl font-extrabold text-center uppercase text-gradient'>
            Jadwal Poliklinik Dokter Spesialis
          </h1>
          <h3 className='text-xl font-extrabold text-center text-gradient'>
            <Moment
              interval={1000}
              format='dddd, DD MMM HH:mm:ss'
              locale='id'
              onChange={(val) => setCurrentDateTime(new Date().toISOString())}
            >
              {currentDateTime}
            </Moment>{' '}
            WIB
            <Moment
              className='hidden'
              interval={1000}
              format='E'
              // format='dddd, DD MMM HH:mm:ss'
              locale='id'
              onChange={(val) => isChangeDaysOfWeekIso(Number(val))}
            >
              {new Date().toISOString()}
            </Moment>
          </h3>
          {/* page */}
          <div className='fixed text-lg bottom-4 right-4 text-primary'>
            Halaman ke-{activeIndex + 1} dari 8
          </div>
          <Swiper
            effect={'fade'}
            // slidesPerView={1}
            // onSwiper={(swiper) => console.log(swiper)}
            modules={[Autoplay, EffectFade]}
            autoplay={{
              delay: 15500,
              disableOnInteraction: false,
            }}
            // loop={true}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex);
              // console.log("slide change", swiper.activeIndex);
            }}
          >
            <SwiperSlide>
              {activeIndex === 0 && (
                <div className='grid grid-cols-1 gap-3 px-3 md:grid-cols-3 lg:grid-cols-4'>
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage1Col1}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage1Col2}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage1Col3}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage1Col4}
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {activeIndex === 1 && (
                <div className='grid grid-cols-1 gap-3 px-3 md:grid-cols-3 lg:grid-cols-4'>
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage2Col1}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage2Col2}
                  />
                  {dataPage2Col3 && (
                    <Jadwal
                      listDocter={listDocter}
                      schedulePoli={dataPage2Col3}
                    />
                  )}
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage2Col4}
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {activeIndex === 2 && (
                <div className='grid grid-cols-1 gap-3 px-3 md:grid-cols-3 lg:grid-cols-4'>
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage3Col1}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage3Col2}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage3Col3}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage3Col4}
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {activeIndex === 3 && (
                <div className='grid grid-cols-1 gap-3 px-3 md:grid-cols-3 lg:grid-cols-4'>
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage4Col1}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage4Col2}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage4Col3}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage4Col4}
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {activeIndex === 4 && (
                <div className='grid grid-cols-1 gap-3 px-3 md:grid-cols-3 lg:grid-cols-4'>
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage5Col1}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage5Col2}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage5Col3}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage5Col4}
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {activeIndex === 5 && (
                <div className='grid grid-cols-1 gap-3 px-3 md:grid-cols-3 lg:grid-cols-4'>
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage6Col1}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage6Col2}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage6Col3}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage6Col4}
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {activeIndex === 6 && (
                <div className='grid grid-cols-1 gap-3 px-3 md:grid-cols-3 lg:grid-cols-4'>
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage7Col1}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage7Col2}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage7Col3}
                  />
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage7Col4}
                  />
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {activeIndex === 7 && (
                <div className='grid grid-cols-1 gap-3 px-3 md:grid-cols-3 lg:grid-cols-4'>
                  <Jadwal
                    listDocter={listDocter}
                    schedulePoli={dataPage8Col1}
                  />
                </div>
              )}
            </SwiperSlide>
          </Swiper>
        </>
      )}
    </div>
  );
  // }
}

export default DocterSchedule;
